class BaseLayer extends Cesium.CustomDataSource {
  constructor(props) {
    props = Object.assign({
      sourceType: "entity",
      name: Cesium.createGuid(),
      id: Cesium.createGuid(),
    }, props);
    super(props);
    this._primitiveCollection = new Cesium.PrimitiveCollection();
    this._id = props.id;
    this._name = props.name;
    this._sourceType = props.sourceType;
    // 用来存储node文件的对象
    this.nodes = {}
    this._layerManager = null
    if (props.entites) {
      let collection = this._getToDoSource(props.entites);
      collection.add(props.entities);
    }
  }

  get id() {
    return this._id;
  }

  get name(){
      return this._name
  }

  get primitives() {
    return this._primitiveCollection;
  }
  get layerManager() {
    return this._layerManager;
  }
}

BaseLayer.prototype.addAll = function(entity, index) {
  if (entity instanceof Cesium.EntityCollection) {
    for (let i = 0; i < entity.values.length; i++) {
      this.add(entity.values[i]);
    }
  }
  // else if(entity instanceof PrimitiveCollection){
  //      for(let i = 0; i < entity.primitives.length; i++){
  //         this.add(entity.get(i));
  //     }
  // }
  else {
    this.add(entity, index);
  }
};

BaseLayer.prototype.add = function(entity, index) {
  let collection = this._getToDoSource(entity);
  let hasit = collection.contains(entity);
  if (!hasit) {
    entity.editLayer = this;
    return collection.add(entity, index);
  }
};

// 添加Node对象
BaseLayer.prototype.addNode = function (node) {
  const entity = node.entity
  let collection = this._getToDoSource(entity);
  let hasit = collection.contains(entity);
  if (!hasit) {
    entity.editLayer = this;
    this.nodes[node.id] = node
    collection.add(entity);
    if (node.nodes) {
      for (var i = 0; i < node.nodes.length; i++) {
        const _node = node.nodes[i]
        const _entity = _node.entity
        collection.add(_entity);
      }
    }
  }
}

// 查找Node对象
BaseLayer.prototype.getNodeById = function (id) {
  return this.nodes[id]
}

// 删除node
BaseLayer.prototype.removeNodeById = function (id) {
  let entity = this.getById(id)
  let collection = this._getToDoSource(entity);
  let node = this.getNodeById(id)
  if (node) {
    delete this.nodes[id]
    collection.remove(entity);
    if (node.nodes) {
      for (var i = 0; i < node.nodes.length; i++) {
        const _node = node.nodes[i]
        const _entity = _node.entity
        this.remove(_entity)
      }
    }
  }
}
// 删除所有node实体
BaseLayer.prototype.removeAllNode = function() {
  for (var key in this.nodes)  {
    let value =  this.nodes[key]
    this.removeNodeById(value.id)
  }
}

BaseLayer.prototype.getAll = function() {
  let arr = [
    this.entities.values,
    this._primitiveCollection.primitives,
  ];
  return arr;
};

BaseLayer.prototype.get = function(id) {
  return this._entityCollection.get(id);
};

BaseLayer.prototype.remove = function(entity) {
  let collection = this._getToDoSource(entity);
  collection.remove(entity);
};

BaseLayer.prototype.clear = function() {
  this.entities.removeAll();
  // this._primitiveCollection.removeAll();
  this.nodes = {}
};

BaseLayer.prototype.getById = function(id){
  let entity = this._entityCollection.getById(id)
  return entity;
}

/**
 * 获取待操作的集合
 * @param {*} entity
 * @returns
 */
BaseLayer.prototype._getToDoSource = function(entity) {
  if (
    entity instanceof Cesium.Primitive ||
    entity instanceof Cesium.PrimitiveCollection ||
    entity instanceof Cesium.Cesium3DTileset
  ) {
    return this._primitiveCollection;
  }
  return this._entityCollection;
};

BaseLayer.prototype.registeManager = function (manager) {
  this._layerManager = manager
}

BaseLayer.prototype.logOff = function () {
  this._layerManager = null
}


export default BaseLayer;
