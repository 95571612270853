import BaseOperation from "./BaseOperation";
import {
  getSegmentInfo,
  screen2cartesian3,
  tangentialCircle
} from "@/util/map";
import { getDistcanceLabelStyle, getNodePoint } from "../../style/index";
import NodeMap from "../../node/index";

export default class EditOperation extends BaseOperation {
  static NAME = "EDIT"
  constructor(props) {
    let defaultParam = Object.assign(
      {
        name: EditOperation.NAME,
        selectFeature: null,
        eventMap: [
          {
            type: "MOUSE_MOVE",
            callback: (map, event, layer) => {
              this.mousemove(map, event, layer, false);
            },
          },
          {
            type: "MOUSE_MOVE",
            modefiy: Cesium.KeyboardEventModifier.CTRL,
            callback: (map, event, layer) => {
              this.mousemove(map, event, layer, true);
            },
          },
          {
            type: "LEFT_DOWN",
            callback: (map, event, layer) => {
              this.mousedown(map, event, layer, false);
            },
          },
          {
            type: "LEFT_DOWN",
            modefiy: Cesium.KeyboardEventModifier.CTRL,
            callback: (map, event, layer) => {
              this.mousedown(map, event, layer, true);
            },
          },
          {
            type: "LEFT_UP",
            callback: (map, event, layer) => {
              this.mouseup(map, event, layer, false);
            },
          },
          {
            type: "LEFT_UP",
            modefiy: Cesium.KeyboardEventModifier.CTRL,
            callback: (map, event, layer) => {
              this.mouseup(map, event, layer, true);
            },
          },
          {
            type: "LEFT_CLICK",
            callback: (map, event, layer) => {
              this.click(map, event, layer, false);
            },
          },
        ],
        canEdit: true
      },
      props
    );
    super(defaultParam);
    this._canEdit = defaultParam.canEdit
    this._updated = false
    this._isDragCircle = false // 是否拖动圆
    this._isChangeRadius = false // 是否改变圆半径
    this._mouseLeftDown = false
    this._pickModel = null;
    this._heightFeature = null;
    this._selectFeature = defaultParam.selectFeature;
    this._hoverFeature = null;
    this._layer = null
    this._vueHandler = defaultParam.vueHandler;
    this._updating = defaultParam.updating
    this._offset = {
      x: 0,
      y: 0
    }
  }

  get selectFeature() {
    return this._selectFeature
  }

  set selectFeature(value) {
    if (value && !(value instanceof NodeMap.Node)) {
      this._selectFeature = value
      console.error(value, `没有进行Node包装，请尽快重构`)
      return
    }
    if (value) {
      if (this._selectFeature && this._selectFeature.id !== value.id) {
        this._selectFeature.getRootNode().unSelect()
      }
      value.getRootNode().onSelect()
    } else {
      if (this._selectFeature) {
        this._selectFeature.getRootNode().unSelect()
      }
    }
    this._selectFeature = value
  }

  get hoverFeature() {
    return this._hoverFeature
  }

  set hoverFeature(value) {
    if (value && !(value instanceof NodeMap.Node)) {
      this._hoverFeature = value
      console.error(value, `没有进行Node包装，请尽快重构`)
      return
    }
    if (value) {
      if (this._hoverFeature) {
        if (this._hoverFeature.id === value.id) {
          return
        } else {
          this._hoverFeature.getRootNode().leave()
        }
      }
      value.getRootNode().hover()
    } else {
      if (this._hoverFeature) {
        this._hoverFeature.getRootNode().leave()
      }
    }
    this._hoverFeature = value
  }

  get pickModel () {
    return this._pickModel
  }

  set pickModel (value) {
    this._pickModel = value
  }

  get layer () {
    return this._layer
  }

  set layer (value) {
    this._layer = value
  }

  get canEdit () {
    return this._canEdit
  }

  _hover(map, event) {
    if (this._mouseLeftDown) {
      if (this._vueHandler && this._vueHandler.closeHovertip) {
        this._vueHandler.closeHovertip()
      }
      return
    }
    const position = event.endPosition? event.endPosition: event.position
    let model = map.scene.pick(position);
    if (model) {
      if (model.id && !(model.id.root instanceof NodeMap.Node)) {
        this.hoverFeature = null
        console.error(model.id, `没有进行Node包装，请尽快重构`)
        return
      }
      model = model.id.root.getRootNode()
      map._container.style.cursor = "pointer"
      if (this.hoverFeature) {
        if (this.hoverFeature.id !== model.id) {
          if (this._vueHandler && this._vueHandler.closeHovertip) {
            this._vueHandler.closeHovertip()
          }
        }
        let position = model.getHovertipPosition(event)
        if (this._vueHandler && this._vueHandler.openHovertip) {
          this._vueHandler.openHovertip({
            id: model.id,
            left: position.x,
            top: position.y,
            type: model.type,
            drawType: model.entity.drawType
          })
        }
      }
      this.hoverFeature = model
    } else {
      map._container.style.cursor = "default"
      if(this.hoverFeature) {
          if (this._vueHandler && this._vueHandler.closeHovertip) {
            this._vueHandler.closeHovertip()
          }
      }
      this.hoverFeature = null
    }
  }

  doHandle(map, event) {
    if (this.canEdit && this._mouseLeftDown && this.selectFeature && this.selectFeature.canEdit) {
      if (this.pickModel) {
        switch (this.selectFeature.type) {
          case NodeMap.Node.POINT:
            if (this.selectFeature.id === this.pickModel.id) {
              let moveEntity = () => {
                map.scene.screenSpaceCameraController.enableRotate = false;
                map.scene.screenSpaceCameraController.enableTranslate = false;
                // 正在开始拖动实体点
                var position = screen2cartesian3(map, {
                  x: event.endPosition.x - this._offset.x,
                  y: event.endPosition.y - this._offset.y
                })
                this._updated = true
                this.selectFeature.move({
                  position
                })
              }
              let moveRadius = () => {
                map.scene.screenSpaceCameraController.enableRotate = false;
                map.scene.screenSpaceCameraController.enableTranslate = false;
                // 正在开始拖动半径
                var centerPosition = this.selectFeature.position;
                var startRadius = this.selectFeature.entity.ellipse.semiMajorAxis.getValue();
                var endPosition = screen2cartesian3(map, event.endPosition);
                var endRadius = Cesium.Cartesian3.distance(centerPosition, endPosition);
                var finalRadius = startRadius + (endRadius - startRadius);
                finalRadius = finalRadius.toFixed(1) * 1;
                this._updated = true;
                this.selectFeature.moveRadius({
                  radius: finalRadius,
                  position: centerPosition
                });
              }
              if (this.selectFeature.entity.ellipse) {
                if (this._isDragCircle) moveEntity();
                if (this._isChangeRadius) moveRadius();
              } else {
                moveEntity();
              }
            }
            break;
          case NodeMap.Node.LINE:
            if (!this.selectFeature.getInflectionPoint || this.pickModel.id === this.selectFeature.id) break
            // 正在开始拖动实体点
            this.selectFeature.initAnimation()
            map.scene.screenSpaceCameraController.enableRotate = false;
            map.scene.screenSpaceCameraController.enableTranslate = false;
            var position = screen2cartesian3(map, event.endPosition)
            var points = this.selectFeature.getInflectionPoint()
            var index = points.findIndex(item=>{
              return item.id === this.pickModel.id
            })
            this._updated = true
            this.selectFeature.move({
              index, 
              position
            })
            break
          case NodeMap.Node.POLYGON:
              if (!this.selectFeature.getInflectionPoint || this.pickModel.id === this.selectFeature.id) break
              // 正在开始拖动实体点
              this.selectFeature.initAnimation()
              map.scene.screenSpaceCameraController.enableRotate = false;
              map.scene.screenSpaceCameraController.enableTranslate = false;
              var position = screen2cartesian3(map, event.endPosition)
              var points = this.selectFeature.getInflectionPoint()
              var index = points.findIndex(item=>{
                return item.id === this.pickModel.id
              })
              this._updated = true
              this.selectFeature.move({
                index, 
                position
              })
              break
          default:
            break;
        }
      }
    }
  }

  mousemove(map, event, layer, pressCTRL) {
    let pick = map.scene.pick(event.endPosition);
    if (pick && pick.id && !(pick.id.root instanceof NodeMap.Node)) {
      console.error(pick.id, `没有进行Node包装，请尽快重构`)
      return
    }
    this._hover(map, event)
    this.doHandle(map, event)
  }

  mousedown(map, event, layer, pressCTRL) {
    this._mouseLeftDown = true
    const model = map.scene.pick(event.position);
    if (model && model.id && !(model.id.root instanceof NodeMap.Node)) {
      console.error(model.id, `没有进行Node包装，请尽快重构`)
      return
    }
    if (model && model.id && model.id.canEdit) {
      if (this.selectFeature) {
        switch (this.selectFeature.type) {
          case NodeMap.Node.POINT:
            let entityPosition = this.selectFeature.position;
            if (this.selectFeature.entity.ellipse) {
              // ellipse对象不为空表示当前实体是圆
              if (model.primitive && model.primitive instanceof Cesium.GroundPolylinePrimitive) {
                // 属于Cesium.GroundPolylinePrimitive表示当前点击为线条
                this._isDragCircle = false;
                this._isChangeRadius = true;
              } else if (model.primitive && (model.primitive instanceof Cesium.Label 
                  || model.primitive instanceof Cesium.Billboard)) {
                this._isDragCircle = true;
                this._isChangeRadius = false;
              }
            }
            const tempCartographic = Cesium.SceneTransforms.wgs84ToWindowCoordinates(map.scene, entityPosition);
            this._offset.x = event.position.x - tempCartographic.x
            this._offset.y = event.position.y - tempCartographic.y
            break;
          default:
            break;
        }
        
      }
      this.pickModel = model.id;
      this.layer = model.id.editLayer;
    }
  }

  mouseup(map, event, layer) {
    this._mouseLeftDown = false
    map.scene.screenSpaceCameraController.enableRotate = true;
    map.scene.screenSpaceCameraController.enableTranslate = true;
    if (this._updated) {
      this._updated = false
      this.selectFeature.cancelAnimation()
      this._updating(this.selectFeature)
    }
    this.pickModel = null;
    this._isDragCircle = false;
    this._isChangeRadius = false;
  }

  click(map, event, layer) {
    const model = map.scene.pick(event.position);
    if (model && model.id && !(model.id.root instanceof NodeMap.Node)) {
      console.error(model.id, `没有进行Node包装，请尽快重构`)
      return
    }
    let node = null
    if ( !model || !model.id ) {
      this.selectFeature = null
    } else {
      let feature = model.id.root;
      node = feature.getRootNode()
      this.selectFeature = node;
    }
    if (this._afterFinish) {
      this._afterFinish(node);
    }
  }
  
  selectFeatureByEntity(entity) {
    let feature = entity;
    this.layer = entity.editLayer;
    let node = feature
    if (feature && feature.root instanceof NodeMap.Node) {
      feature = feature.root
      node = feature.getRootNode()
    } else {
      console.error(feature, `没有进行Node包装，请尽快重构`)
    }
    this.selectFeature = feature;
    if (this._afterFinish) {
      this._afterFinish(node);
    }
  }

  cancelSelectFeature () {
    this.selectFeature = null 
  }

  _updateLabel(old, type) {
    if (type === "line") {
      const len = old.length;
      const idx = Math.floor(len / 2);
      return old[idx];
    }
    let polyCenter = Cesium.BoundingSphere.fromPoints(old).center;
    return polyCenter;
  }

  _addMiddlePoint(en, middleIndex, newPo) {
    const insertIndex = middleIndex + 1;
    if (en.polyline && !en.polygon) {
      let old;
      if (!en.position.getValue) {
        old = en.polyline.positions.slice();
      } else {
        old = en.polyline.positions.getValue().slice();
      }

      old.splice(insertIndex, 0, newPo);

      en.polyline.positions = new Cesium.CallbackProperty(function () {
        return old;
      }, false);

      this._generateInfo(en, old, "markerLine");
      return;
    }

    if (en.polygon) {
      const old = en.polygon.hierarchy.getValue().positions.slice();
      if (insertIndex >= old.length) {
        old.push(newPo);
      } else {
        old.splice(insertIndex, 0, newPo);
      }

      en.polygon.hierarchy = new Cesium.CallbackProperty(function () {
        return new Cesium.PolygonHierarchy(old);
      }, false);

      // if have polyline(outline) , uptdate it
      if (en.polyline) {
        const d = old.slice();
        d.push(d[0]);
        en.polyline.positions.setCallback(function () {
          return d;
        }, false);
      }
      this._generateInfo(en, old, "markerPolygon");
      this._heightLight(null);
      return;
    }
  }

  _genratedMiddlePoint(selectEn) {
    const { drawType, editLayer } = selectEn;
    let old;
    if (drawType === "markerLine") {
      if (!selectEn.polyline.positions.getValue) {
        old = selectEn.polyline.positions;
      } else {
        old = selectEn.polyline.positions.getValue().slice();
      }
    }
    if (drawType === "markerPolygon") {
      old = selectEn.polygon.hierarchy.getValue().positions.slice();
    }
    if (old) {
      const middles = [];
      old.forEach((pt, idx) => {
        if (old[idx + 1]) {
          const id = `middle-point-${idx}`;
          const position = {
            x: (pt.x + old[idx + 1].x) / 2,
            y: (pt.y + old[idx + 1].y) / 2,
            z: (pt.z + old[idx + 1].z) / 2,
          };
          const mid = new Cesium.Entity({
            id,
            position,
            billboard: {
              image: "/img/map/plus.png",
              disableDepthTestDistance: Number.POSITIVE_INFINITY,
              width: 24,
              height: 24,
              color: Cesium.Color.WHITE,
            },
          });
          mid.editLayer = editLayer;
          mid.parent = selectEn;
          middles.push(mid);
          editLayer.add(mid);
        }

        if (drawType === "markerPolygon" && idx === old.length - 1) {
          const id = `middle-point-${idx}`;
          const position = {
            x: (pt.x + old[0].x) / 2,
            y: (pt.y + old[0].y) / 2,
            z: (pt.z + old[0].z) / 2,
          };
          const mid = new Cesium.Entity({
            id,
            position,
            billboard: {
              image: "/img/map/plus.png",
              disableDepthTestDistance: Number.POSITIVE_INFINITY,
              width: 24,
              height: 24,
              color: Cesium.Color.WHITE,
            },
          });
          mid.editLayer = editLayer;
          mid.parent = selectEn;
          middles.push(mid);
          editLayer.add(mid);
        }
      });

      selectEn.middlePoint = middles;
    }
  }

  _generateInfo(en, newPositions, geoType) {
    const { labels, lengthLabel, editLayer } = en;
    if (labels) {
      labels.forEach((la) => editLayer.remove(la));
      let _totalLength = 0;
      let _lengthLabel = [];
      if (lengthLabel) {
        lengthLabel.forEach((la) => editLayer.remove(la));
      }
      const newLabels = newPositions.map((pt, idx) => {
        const lab = new Cesium.Entity({
          id: `${en.id}#${idx}`,
          position: pt,
          ...getNodePoint("markerPoint", `${idx + 1}`, false),
        });

        lab.editLayer = editLayer;
        lab.parent = en;
        lab.canEdit = true;
        editLayer.add(lab);

        if (lengthLabel && newPositions[idx + 1]) {
          const info = getSegmentInfo(this._map, pt, newPositions[idx + 1]);
          _totalLength += info.length;
          let dis = info.length.toFixed(2);
          let suffix = "m";
          if (dis > 1000) {
            dis = (dis / 1000).toFixed(2);
            suffix = "km";
          }
          let primitive = new Cesium.Entity({
            id: `${en.id}#length#${idx}`,
            position: info.mindPoint,
            ...getDistcanceLabelStyle(dis, suffix),
          });
          const infoLabel = editLayer.add(primitive);
          _lengthLabel.push(infoLabel);
        }

        if (idx === newPositions.length - 1 && geoType === "markerPolygon") {
          const info = getSegmentInfo(this._map, pt, newPositions[0]);
          _totalLength += info.length;
          let dis = info.length.toFixed(2);
          let suffix = "m";
          if (dis > 1000) {
            dis = (dis / 1000).toFixed(2);
            suffix = "km";
          }
          let primitive = new Cesium.Entity({
            id: `${en.id}#length#${idx}`,
            position: info.mindPoint,
            ...getDistcanceLabelStyle(dis, suffix),
          });
          const infoLabel = editLayer.add(primitive);
          _lengthLabel.push(infoLabel);
        }

        return lab;
      });

      en.labels = newLabels;

      if (lengthLabel) {
        en.lengthLabel = _lengthLabel;
        en.horiDistance = _totalLength;
      }
    }
  }

  destroy() {
    this.selectFeature = null
    this.hoverFeature = null
    this.pickModel = null
  }
}
