import EditOperation from "./EditOperation"
import {
  screen2cartesian3
} from "@/util/map";
import NodeMap from "../../node/index";

export default class NormalOperation extends EditOperation {
  static NAME = "NormalOperation"
  constructor(props) {
    let defaultParam = Object.assign(
      {
        name: NormalOperation.NAME,
        selectFeature: null,
      },
      props
    );
    super(defaultParam);
  }
  mousemove(map, event, layer, pressCTRL) {
    let pick = map.scene.pick(event.endPosition);
    if (pick && pick.id && !(pick.id.root instanceof NodeMap.Node)) {
      if (pick.id.category !== 'MIDDLEICON') { // 排除一下鼠标中键按下时绘制的临时图层
        console.error(pick.id, `没有进行Node包装，请尽快重构`)
      }
      return
    }
    this._hover(map, event)
    if (this.selectFeature) {
      switch (this.selectFeature.category) {
        case NodeMap.Node.MARK:
          this.doHandle(map, event)
          break
      }
    }
  }
}
