export default class EventObject {
  constructor() {
    this._event = [];
  }

  get event() {
    return this.event
  }

  on(eventType, callback, context) {
    this._event.push({ eventType, callback, context });
  }

  un(eventType, callback) {
    let index = this._event.findIndex(
      (x) => x.eventType === eventType && x.callback === callback
    );
    if (index !== -1) {
      this._event.splice(index, 1);
    }
  }

  raiseEvent(eventType, eventarg) {
    this._event.map((x) => {
      if (x.eventType === eventType) {
        if (x.context) {
          x.callback.call(x.context, eventarg);
        } else {
          x.callback.call(this, eventarg);
        }
      }
    });
  }
}
