import EventObject from "../../EventObject";

export default class BaseOperation extends EventObject{
    static NAME = 'BASEOPERATION'
    constructor(props){
        let defaultParam = Object.assign(
            {
                name: BaseOperation.NAME,
                afterFinish: ()=>{}
            },
            props
        );
        super(defaultParam);
        this._name = defaultParam.name;
        this._eventMap = defaultParam.eventMap? defaultParam.eventMap: [];
        this._map = defaultParam.map;
        this._layer = defaultParam.layer;
        this._afterFinish = defaultParam.afterFinish;
        this.on('addNodePoint', this.addNodePoint)
        this.on('finishDraw', this.finishDraw)
    }
    get name() {
        return this._name;
    }
    get eventMap() {
        return this._eventMap;
    }
    get map() {
        return this._map
    }
    get layer() {
        return this._layer;
    }
    set layer(newLayer) {
        this._layer = newLayer;
    }
    addNodePoint() {}
    finishDraw() {}
    destroy() {}
}
